
/// Bootstrap overwrite
$primary: #00B6A4;
$secondary: #283841;
$accent: #F7AEA4;

$red: #EA584A;
$green: #03875E;
$blue: #00B6A4;
$pink: #F7AEA4;
$yellow: #F59C32;

$black: #283841;
$white: #FFFFFF;

// @doc https://getbootstrap.com/docs/4.3/utilities/embed/#aspect-ratios
$embed-responsive-aspect-ratios: (
                (35 17),
                (36 40),
                (1300 934),
);

// @doc https://getbootstrap.com/docs/4.3/utilities/sizing/
$sizes: (
        header: 500px,
        20: 20%,
        55px: 55px
);

// @doc https://getbootstrap.com/docs/4.3/utilities/spacing/
$spacer: 6rem;
$spacers: (
);

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#variables
$grid-columns: 12;

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#gutters
$grid-gutter-width: 12rem;

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#grid-tiers
$grid-breakpoints: (
        xs: 0, // phone
        sm: 576px, // phone
        md: 768px, // tablet
        lg: 992px, // desktop
        xl: 1400px, // wide desktop
        xxl: 2740px, // 4k screen
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1100px,
        xl: 1400px,
);
