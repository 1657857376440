

section.experience{
    height: 100vh;
    padding: 0;

    &>.inner{
        position: sticky;
        z-index: 1;
        top:0;
        height: 100vh;
        white-space: nowrap;
        overflow-x: hidden;

        .slide{
            position: absolute;
            top:0;
            left:0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 6rem 0;
            height: 100%;
            width: 100%;
            white-space: normal;
            overflow: hidden;
            pointer-events: none;

            .bubble{
                position: relative;
                overflow: hidden;
                background: #fff;
                border-radius: 140rem;
                width: 0;
                height: 0;
                transition: width 0.75s linear, height 0.75s linear;
                z-index: 100;
                pointer-events: auto;

                .container{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100vw;
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p{
                        text-align: center;
                        font-size: 5rem;

                        @include media-breakpoint-down(sm){
                            font-size: 3.5rem;
                        }

                        span{
                            font-weight: 800;

                            &.pink{
                                color: #EE0000;
                            }
                        }
                    }
                }
            }
        }
    }

    .container{
        &.container-box{
            max-height: 74rem;
            height: 100%;

            @include media-breakpoint-down(sm){
                padding-right: 3rem;
                padding-left: 3rem;
                font-size: 2.5rem;
            }

            .row{
                height: 100%;

                .col{
                    height: 100%;

                    .box{
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding: 10rem;
                        background: $secondary;
                        border-radius: 48px;
                        color: $white;
                        font-size: 5rem;
                        text-align: center;
                        overflow: hidden;
                        pointer-events: auto;

                        @include media-breakpoint-down(sm){
                            padding: 3rem;
                        }

                        &.inview{
                            .gravbox{
                                opacity: 1;
                            }
                        }

                        .gravbox{
                            position: absolute;
                            opacity: 0;
                            transition: opacity 0.5s ease;
                            top: 0;
                            left: 0;
                            font-size: 4.5rem;
                            border: 2px solid;
                            border-radius: 4rem;
                            padding: 0.5rem 2rem;

                            @include media-breakpoint-down(sm){
                                font-size: 2.5rem;
                            }

                            &.red{
                                border-color: $red;
                            }

                            &.blue{
                                border-color: $primary;
                            }

                            &.green{
                                border-color: $green;
                            }

                            &.pink{
                                border-color: $pink;
                            }

                            &.yellow{
                                border-color: $yellow;
                            }
                        }

                        .inner{
                            position: relative;
                            z-index: 10;
                            pointer-events: none;

                            p{
                                max-width: 85rem;
                            }

                            button{
                                pointer-events: auto;
                                margin-top: 2rem;
                                font-size: 5.5rem;
                                font-family: 'Abril Fatface', serif;
                            }
                        }

                        canvas{
                            position: absolute;
                            top:0;
                            left: 0;
                            z-index: 0;
                            background: none !important;
                            border-radius: 48px;
                        }
                    }
                }
            }
        }
    }
}
