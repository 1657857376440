.our-challenge{
    color: $white;
    padding: 4rem 0;

    h3{
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 400;
    }
    h4{
        font-size: 6rem;
        font-weight: 800;
        color: $blue;
    }

    .infos{
        background: rgba(#919BA0, 0.15);
        border-radius: 45px;
        padding: 3rem;

        label{
            font-size: 2.1rem;
            font-weight: bold;
            color: $blue;
            display: block;
        }
    }
}
