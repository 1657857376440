.banner.blog{
    position: relative;
    background: #ffffff;

    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 65vw;
        background: $pink;
        border-bottom-right-radius: 45px;
    }

    .container{
        height: 100%;

        .row{
            height: 100%;

            .featured{
                height: 100%;

                .content{
                    padding: 3rem 10rem 3rem 6rem;
                    border-radius: 45px;
                    background: $secondary;
                    margin-right: -20%;
                    z-index: 10;
                    color: $white;

                    .categories{
                        margin: 0 -2rem;

                        .cat{
                            display: inline-block;
                            margin: 0 2rem;
                            background: rgba(#ffffff, 0.15);
                            padding: 0.75rem 1.5rem;
                            border-radius: 45px;
                            line-height: 1;
                        }
                    }

                    h3{
                        font-family: 'Montserrat', sans-serif;
                        font-size: 3.4rem;
                        font-weight: 800;
                        color: #ffffff;
                        margin-top: 4rem;
                    }

                    span{
                        display: block;
                        color: $primary;
                        margin-bottom: 2rem;
                    }

                    p{
                        font-size: 2.4rem;
                        font-weight: 600;
                    }

                    a{
                        margin-top: 3rem;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
                .image{
                    position: relative;
                    overflow: hidden;
                    border-radius: 45px;
                    width: 160rem;
                    height: 60rem;

                    img{
                        object-fit: cover;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.post-listing{
    input{
        padding: 1rem 3rem;
        border: 1px solid $secondary;
        border-radius: 45px;
        margin-bottom: 4rem;

        &:focus{
            outline: none;
        }
    }

    aside{
        .cats{
            margin-bottom: 4rem;

            label{
                color: $primary;
                font-family: 'Montserrat', sans-serif;
                font-size: 3.4rem;
                font-weight: 800;
            }

            ul{
                list-style: none;
                padding: 0;

                li{
                    margin-top: 1rem;
                }
            }

            &:last-child{
                margin: 0;
            }
        }
    }
}
