.error {
  min-height: 100vh;

  .img-oups {
    position: relative;
    margin-top: -10%;
    left: 5%;
    @include media-breakpoint-down(md) {
      left: 0%;
      margin: 2rem auto;
      width: 50%;
      display: block;
    }
  }

  p {
    font-size: 3rem;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      padding-left: 0;
      padding-right: 0;
    }
  }

  h1 {
    @include media-breakpoint-down(md) {
      font-size: 16rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 8.5rem;
    }
  }

  h2 {
    @include media-breakpoint-down(md) {
      font-size: 11rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 5.2rem;
    }
  }

}
