section.banner.rapports.single{
    &>.inner:first-child{
        h1{
            font-size: 4rem;font-weight: 300;
        }
        h2{
            color: #fff;
            font-family: Montserrat,sans-serif;
            font-size: 12rem;
            font-weight: 700;
            line-height: 1;

            @include media-breakpoint-down(lg){
                font-size: 6rem;
            }
        }
    }
    &>.inner:nth-child(2) {
        background: none;
        border-radius: 0;

        p{
            padding: 7rem 0;
            font-weight: 700;
            font-size: 3.2rem;
            margin: 0;
        }

        &:before{
            content: '';
            position: absolute;
            top: 9rem;
            left: 0;
            right: 0;
            bottom: 9rem;
            background: #fff;
            border-radius: 5rem;
        }
    }
}

.white-small-bg{
    position: relative;

    &:before{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 70%;
        width: 100%;
        background: #ffffff;

        @include media-breakpoint-down(lg){
            width: 90%;
        }
    }

    &>*{
        position: relative;
        z-index: 10;
    }

    &-left{
        &:before{
            left: 0;
            border-top-right-radius: 5rem;
            border-bottom-right-radius: 5rem;
        }
    }

    &-right{
        &:before{
            right: 0;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
        }
    }
}

.avantages{
    font-size: 0;

    .avantage{
        display: inline-block;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;

        .inner{
            display: flex;
            flex-direction: column;
            padding: 8rem 4rem;
            border-radius: 5rem;
            width: 100%;
            height:100%;

            .icon{
                background: #ffffff;
                border-radius: 100%;
                padding: 2rem;
                width: 9rem;
                height: 9rem;
                margin-bottom: 3rem;

                img{
                    width:100%;
                    height:100%;
                    object-fit: contain;
                }
            }

            label{
                display: block;
                font-size: 3rem;
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 2rem;
            }

            p{
                font-size: 2rem;
            }
        }
    }
}

.realisations{
    margin-top: 4rem;

    .realisation{

        .inner{
            position: relative;
            display: block;
            border-radius: 5rem;
            background: #ffffff;
            heighT: 100%;

            &:hover{
                picture{
                    img{
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                &:after{
                    animation: bounceRight 2s ease infinite;
                }
            }

            picture{
                position: relative;
                display: block;
                border-radius: 5rem;
                overflow: hidden;
                width: 100%;
                height: 0;
                padding-bottom: 120%;

                @include media-breakpoint-down(lg){
                    padding-bottom: 80%;
                }

                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: translate(-50%, -50%);
                    transition: transform 0.5s ease;
                }
            }

            label{
                cursor: pointer;
                padding: 3rem 5rem 6rem;
                font-weight: 700;
                font-size: 2rem;
                color: #000000;
                line-height: 1.2;
                width: 100%;
            }

            &:after{
                content: '';
                position: absolute;
                right: 4rem;
                bottom: 2rem;
                width: 4rem;
                background: url('../images/icons/arrow-big.svg') no-repeat center center / 60%;
                height: 4rem;
                filter: brightness(0) saturate(100%) invert(40%) sepia(85%) saturate(641%) hue-rotate(322deg) brightness(97%) contrast(90%);
                border-radius: 100%;
                border: 2px solid $black;

                @keyframes bounceRight {
                    0%,
                    20%,
                    50%,
                    80%,
                    100% {
                        -ms-transform: translateX(0);
                        transform: translateX(0);
                    }
                    40% {
                        -ms-transform: translateX(-30px);
                        transform: translateX(-30px);
                    }
                    60% {
                        -ms-transform: translateX(-15px);
                        transform: translateX(-15px);
                    }
                }
            }
        }
    }
}
