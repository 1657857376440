.thumbnails{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;

    &:hover{
        img.icon{
            width: 7rem;
            height: 7rem;
        }
    }

    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.icon{
            z-index: 10;
            width: 5rem;
            height: 5rem;
            transition: width 0.5s ease, height 0.5s ease;
        }

        &.thumb{
            width: 101%;
            height: 101%;
        }
    }
}
iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.border-radius{
    overflow: hidden;
    min-height: 52rem;

    @include media-breakpoint-down(sm){
        min-height: 30rem;
    }

    @include media-breakpoint-down(xs){
        order: 1;
    }

    &.left{
        border-bottom-left-radius: 5rem;
        border-top-left-radius: 5rem;
    }

    &.right{
        border-bottom-right-radius: 5rem;
        border-top-right-radius: 5rem;
    }
}
