.btn{
    --mainColor: #{$primary};
    --secondaryColor: #{$secondary};
    --padding: 1.2rem 2rem;
    --font-size: 2rem;
    $rgba: var(--mainColor);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding);
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 8.9rem;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: var(--font-size);
    line-height: 1;

    &:focus{
        box-shadow: none;
    }

    &.no-interaction{
        pointer-events: none;
    }

    svg{
        &.fill{
            path{
                fill: currentColor;
            }
        }
        &:not(.fill){
            path{
                stroke: currentColor;
            }
        }
        path{
            transition: stroke 0.5s ease, fill 0.5s ease;
        }
    }

    &:hover{
        background-color: rgba($rgba, 0);
        color: var(--mainColor);
    }

    &.inverted{
        background-color: rgba($rgba, 0);
        color: var(--mainColor);

        &:hover, &:active{
            background-color: var(--mainColor);
            color: var(--secondaryColor);
        }
    }

    &.white{
        --mainColor: #{$white};
    }

    &.black{
        --mainColor: #{$black};
        --secondaryColor: #{$white};

        &.hover-yellow:hover{
            --mainColor: #{$yellow};
        }

        &.hover-blue:hover{
            --mainColor: #{$blue};
        }

        &.hover-pink:hover{
            --mainColor: #{$pink};
        }

        &.hover-green:hover{
            --mainColor: #{$green};
        }
    }

    &.pink{
        --mainColor: #{$accent};
        --secondaryColor: #4F4F4F;
    }

    &.yellow{
        --mainColor: #{$yellow};
        --secondaryColor: #{$black};
    }

    &.blue{
        --mainColor: #{$primary};
        --secondaryColor: #{$black};
    }

    &.green{
        --mainColor: #{$green};
        --secondaryColor: #{$white};
    }

    &.filtered {
        background: none;
        color: $white;
        border-color: $white;
        background: $secondary;
        margin: 0 1.5rem 0;
        width: 17rem;
        --font-size: 2rem;
        font-weight: 500;
        --padding: 1rem;

        &:first-child{
            margin-left:0;
        }

        &:last-child{
            margin-right:0;
        }

        &.active, &:hover {
            color: $primary;
            border-color: $primary;
        }
    }

    &.more {
        background-color: transparent;
        font-family: Montserrat, sans-serif !important;
    }
}
