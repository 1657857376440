/* main: ../style.scss*/
form {
	position: relative;
	margin: 3rem -1rem 0;
	font-size: 0;

	[data-success]{
		font-size: 2.6rem;
		padding-top: 1rem;
		display: none;
	}

	.form-item{
		display: inline-block;
		font-size: 1.6rem;
		padding: 1rem;
		vertical-align: top;

		label{
			width: 100%;
		}

		@include media-breakpoint-down(md) {
			display: block;
			font-size: 2rem;
			width: 100%!important;
		}

		&[data-error="message"]{
			label.error{
				margin-top: -2.5rem;
			}
		}

		label.error{
			margin-top: -2rem;
			padding: 3rem 3rem 1rem;
			font-size: 14px;
			color: #383333;
			background: $pink;
			z-index: 5;
			position: relative;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
		}

		.errors{
			padding-top: 0.5rem;

			[data-type]{
				display: none;
				margin: 0;
				padding: 0.5rem 3rem;
				font-size: 14px;
				color: $dark;
			}
		}

		.error{
			min-height: 0;
		}

		input, select, textarea{
			position: relative;
			z-index: 10;
			width: 100%;
			outline: none;
			border: none;
			border-radius: 1.4rem;
			padding: 1.5rem 3rem;

			-moz-appearance:none; /* Firefox */
			-webkit-appearance:none; /* Safari and Chrome */
			appearance:none;

			&.error{
				border-bottom: 5px solid #D01010;
			}
		}

		&.select{
			position: relative;

			select{
				padding-right: 5rem;
			}

			&:after{
				content: '';
				background: url('../images/icons/arrow-dropdown.svg') no-repeat center center / contain;
				position: absolute;
				right: 3.5rem;
				top: 3.5rem;
				transform: translateY(-50%);
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		button{
			--padding: 1rem 2rem;
			--secondaryColor: #{$green};
		}
	}
}
