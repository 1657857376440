section{
    &.banner.equipe.single>.inner:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10rem;
        overflow: hidden;

        @include  media-breakpoint-down(sm){
            padding-bottom: 6rem;
            padding-top: 4rem;
            background: var(--secondary);

            .slick-slide{
                .container{
                    background: var(--white);
                    padding-top: 5rem;
                    padding-bottom: 5rem;
                    border-radius: 5rem;

                    img{
                        width: 44rem;
                        margin: -20rem auto 5rem;
                    }
                }
            }
        }

        .slider.banner-team{
            max-width: 100%;
            margin-bottom: 8rem;

            .slick-list{
                overflow: hidden !important;
            }

            .slick-slide>div{
                text-align: center;

                @include media-breakpoint-down(sm){
                    padding-top: 18rem;
                }

                &>*{
                    text-align: left;
                }
            }

            .slick-dots{
                li{
                    &.slick-active{
                        button{
                            &:before{
                                color: var(--white);
                            }
                        }
                    }
                    button{
                        height: 25px;
                        width: 25px;

                        &:before{
                            color: var(--white);
                            width: 25px;
                            height: 25px;
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        .tabulations {
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            span {
                position: relative;
                padding-top: 1.3rem;
                overflow: hidden;
                cursor: pointer;
                font-weight: 600;
                opacity: 0.5;
                transition: opacity 0.5s ease;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -100%;
                    height: 2px;
                    width: 100%;
                    opacity: 0;
                    background: $secondary;
                    transition: opacity 0.5s ease, left 0.5s ease;
                }

                &.current, &:hover {
                    opacity: 1;

                    &:before {
                        left: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.slider-equipe{
        padding-bottom: 15rem;
    }

    &.emplois{
        position: relative;
        padding-bottom: 0;
        margin-top: -5rem;
        z-index: 10;
        background: $white;
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;

        h3{
            font-size: 8rem;
            font-weight: 800;
            font-family: 'Abril Fatface', serif;

            @include media-breakpoint-down(sm){
                font-size: 6rem;
            }
        }
        h2{
            font-size: 7rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            margin-bottom: 5rem;
            text-transform: initial;

            @include media-breakpoint-down(sm){
                font-size: 5rem;
            }
        }

        p{
            margin-bottom: 2rem;
        }

        ul{
            list-style: none;
            padding-left: 0;
            margin: 3rem 0 5rem;

            li{
                position: relative;
                margin-bottom: 1rem;
                padding-left: 3.4rem;

                &:before {
                    content:"·";
                    position: absolute;
                    top: -0.5rem;
                    left: 0;
                    font-size: 9rem;
                    line-height: 1.8rem;
                }
            }
        }

        strong{
            display: block;
            margin-top: 4rem;
            font-size: 3.5rem;
            font-weight: 900;
        }

        .border-offres{
            border-top-left-radius: 5rem;
            border-top-right-radius: 5rem;
            border: 1px solid $secondary;
            border-bottom: none;
            padding: 5rem 4rem 10rem;
            max-width: 160rem;
            margin: 10rem auto 0;

            .offres{
                .col{
                    @include media-breakpoint-down(sm){
                        padding-left: 0rem;
                        padding-right: 0rem;
                    }
                }
                h2{
                    font-size: 3.5rem;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 4rem;
                    margin-bottom: 4rem;
                    font-family: 'Montserrat', sans-serif;
                    text-transform: uppercase;

                    @include media-breakpoint-down(sm){
                        font-size: 3rem;
                    }
                }

                table{
                    border-spacing: 0 2rem;

                    th, td{
                        padding: 2rem 3rem;
                        @include media-breakpoint-down(sm){
                            padding: 1rem 2rem;
                        }
                    }
                    th{
                        font-size: 2rem;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                    tr.offre{
                        border-spacing: 5rem;

                        td {
                            border-top: 1px solid $secondary;
                            border-bottom: 1px solid $secondary;
                            font-size: 1.5rem;

                            &:first-child {
                                border-left: 1px solid $secondary;
                                border-top-left-radius: 5rem;
                                border-bottom-left-radius: 5rem;
                                font-size: 2.4rem;
                                font-weight: bold;
                            }

                            &:nth-child(3) {
                                font-size: 2rem;
                            }

                            &:last-child {
                                border-right: 1px solid $secondary;
                                border-top-right-radius: 5rem;
                                border-bottom-right-radius: 5rem;
                                text-align: right;
                                padding-right: 2rem;
                            }

                            a{
                                position: relative;
                                padding: 0.2rem 0.6rem;
                                border: 1px solid;
                                width: 5rem;
                                height: 5rem;

                                svg{
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 2.6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
