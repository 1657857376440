/* main: ../style.scss*/
@each $sizeName, $sizePx in $container-max-widths {
    @include media-breakpoint-only($sizeName) {
        .col-full-left-#{$sizeName}{
            padding-left: calc((100vw - #{$sizePx}) / 2 + #{$spacer}) !important;
        }
        .col-full-right-#{$sizeName}{
            padding-right: calc((100vw - #{$sizePx}) / 2 + #{$spacer}) !important;
        }
    }
}

@each $sizeName, $sizePx in $container-max-widths {
    @include media-breakpoint-up($sizeName) {
        .sizewidth-#{$sizeName}{
            width: calc((100vw - #{$sizePx}) / 2) !important;
        }
        .sizewidth-#{$sizeName}{
            width: calc((100vw - #{$sizePx}) / 2) !important;
        }
    }
}
// On event (hover, active, focus) states
@mixin on-event($self: false) {
    @if $self {
        &,
        &:active,
        &.active,
        &:focus,
        &:hover {
            @content;
        }
    } @else {
        &:active,
        &.active,
        &:focus,
        &:hover {
            @content;
        }
    }
}
// Quickly center an element (margin auto)
@mixin push-auto {
    margin: {
        left: auto;
        right: auto;
    }
}

// Includes Default pseudo-element style (block, absolute, content)
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
// Centering element
@mixin center($position: 'both') {
    position: absolute;

    @if $position == 'vertical' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    } @else
    if $position == 'horizontal' {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translate(-50%);
    } @else
    if $position == 'both' {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}
// Background-gradient
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == 'vertical' {
        background: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else
    if $orientation == 'horizontal' {
        background: -webkit-gradient(linear, left top, right top, from($start-color), to($end-color));
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}
// Background-image
@mixin bg($img: false, $background-position: false, $background-size: false, $background-color: false) {
    background-repeat: no-repeat;
    @if $img {
        background-image: url('#{$path--rel}/#{$img}');
    }
    @if $background-position {
        background-position: $background-position;
    }
    @if $background-size {
        background-size: $background-size;
    }
    @if $background-color {
        background-color: $background-color ;
    }
}
