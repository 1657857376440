.aspect-ratio{
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    cursor: pointer;

    &:hover{
        &:before{
            transform: translate(-50%, -50%) scale(1.2);
        }
    }

    &.clicked{
        &:before{
            content: none;
        }
    }

    &:before{
        content: '';
        background: url('../images/icons/red-play.svg') no-repeat center center / contain;
        position: absolute;
        z-index: 12;
        top: 50%;
        left: 50%;
        width: 22em;
        height: 22rem;
        transition: transform 0.5s ease;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    .notloaded{
        display: none;
    }
}
