.approches{
    position: relative;

    svg.line-animated{
        position: absolute;

        @include media-breakpoint-down(md){
            display: none;
        }

        &.first{
            right: 28%;
            top: 12vh;
        }
        &.second{
            left: 20%;
            top: 46%;
        }
    }
    .block-approche{
        position: relative;
        padding: 8rem 7rem;

        br{
            display: inline !important;
        }

        .icon-top{
            position: absolute;
            top: 0;
            right: 0;
            width: 40%;
        }

        &.first{
            @include media-breakpoint-down(md){
                padding-right: 23vw;
            }

            .icon-top{
                width: 37%;
                top: 5.5%;
                right: 4%;

                @media screen and (max-width: 480px){
                    top: 0;
                }
            }
        }

        &.second{
            margin-top: 26%;

            .icon-top{
                width: 24%;
                top: -1%;
                left: 55%;

                @media screen and (max-width: 480px){
                    left: 60%;
                }

                @include media-breakpoint-down(md){
                    left: 0%;
                }
            }

            @include media-breakpoint-down(md){
                margin-top: 0;
                padding-right: 7rem;
                padding-left: 23vw;
            }

            @media screen and (max-width: 480px){
                padding-left: 7rem;
            }
        }

        &.last{
            margin-top: -19%;

            @include media-breakpoint-down(md){
                margin-top: 0;
                padding-right: 23vw;
            }

            .icon-top{
                width: 32%;
                top: -11%;
                right: 16%;

                @media screen and (max-width: 480px){
                    top: -3%;
                }

                @include media-breakpoint-down(md){
                    top: 0;
                    right: 0;
                }
            }
        }

        h3{
            font-size: 7rem;
            line-height: 1;
            margin-bottom: 3.5rem;

            @include media-breakpoint-down(md){
                font-size: 6rem;
            }

            span{
                font-size: 8rem;
                font-family: 'Abril Fatface', serif;
                font-weight: normal;

                @include media-breakpoint-down(md){
                    font-size: 6rem;
                }
            }
        }

        h4{
            font-size: 2.4rem;
            margin-bottom: 4.5rem;
            line-height:  1.4;

            span{
                font-size: 2.4rem;
                font-weight: bold;
                padding: 1rem 2rem;
            }
        }
    }
}
