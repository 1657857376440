.full-image{
    display: flex;
    flex-direction: column;
    padding: 0;

    &__over{
        position: relative;
        width: 90%;
        padding-bottom: 35vw;
        max-width: 60vw;
        overflow: hidden;
        z-index: 2;

        img{
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &--left{
            margin-bottom: -10rem;
            border-top-right-radius: 5rem;
            border-bottom-right-radius: 5rem;

            img{
                top: 50% !important;
                left: 50% !important;
                height: 100% !important;
                transform: translate(-50%, -50%);
            }
        }
        &--right{
            margin-top: -10rem;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
            align-self: flex-end;

            img{
                top: 50% !important;
                left: 50% !important;
                height: 100% !important;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__inner{
        position: relative;
        height: 80vh;
        overflow: hidden;
        padding: 0;
        z-index: 1;

        @include media-breakpoint-down(md){
            height: 40vh;
        }

        &.no-height{
            height: auto;

            img{
                position: relative;
                height: auto;
            }
        }

    }

    img{
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;

        @include media-breakpoint-down(md){
            height: 60vh;
        }
    }
}
