.equipe {
  .scroll{
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: block;
  }
  .card-equipe{
    border-radius: 5rem;
    height: 60vh;

    h2, span {
      font-size: 5rem!important ;
    }
  }
  .img-equipe{
    z-index:1;
    img {
      height: 160px;
      width: auto;
    }
  }
}

