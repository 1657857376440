/* main: ../style.scss*/
html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 10px;
    font-size: clamp(8px, 0.52vw, 10px);
}

body {
    font-size: 1.6rem;
    overflow-x: hidden;

    ::-moz-selection { /* Code for Firefox */
        color: $white;
        background: $primary;
    }

    ::selection {
        color: $white;
        background: $primary;
    }


    .container.short{
        max-width: 82rem;
    }
    .container.medium{
        max-width: 125rem;
    }
    .container.large{
        max-width: 164rem;
    }
    .container, .container-fluid{
        @include media-breakpoint-down(sm){
            padding-left: 5rem;
            padding-right: 5rem;
        }
        &.small-gutter{
            padding-left: 3rem;
            padding-right: 3rem;

            .row{
                margin-left: -3rem;
                margin-right: -3rem;

                .col{
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
            }
        }
        &.xsmall-gutter{
            padding-left: 1rem;
            padding-right: 1rem;

            .row{
                margin-left: -1rem;
                margin-right: -1rem;

                .col{
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }
}
video{
    max-width: 100%;
}
[data-paralax]{
    //transition: transform 0.25s linear;
}

body::-webkit-scrollbar {
    width: 5px;
    background: $secondary;
}

body::-webkit-scrollbar-track {
    box-shadow: none;
}

body::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 10px;
    background-color: $primary;
    outline: none;
}

*,
*::after,
*::before {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}

a {
    text-decoration: none;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    line-height: 1;
    color: $primary;

    @include on-event {
        text-decoration: none;
    }
}

img{
    max-width: 100%;
    height: auto;
}

.grey-background{
    background: #F3F4F4 !important;
}

.secondary-background{
    background: $secondary !important;
}

.pink-background{
    background: $pink !important;
}

.red-background{
    background: $red !important;
}

.green-background{
    background: $green  !important;
}

.blue-background{
    background: $blue  !important;
}

.light-blue-background{
    background: #F2FAF8  !important;
}

.yellow-background{
    background: $yellow !important;
}
.white-background{
    background: $white !important;
}

.grey-color{
    color: #F3F4F4;
}
.white-color{
   color: $white;
}
.green-color{
    color: $green ;
}
.blue-color{
    color: $blue;
}

picture{
    display: inline-block;
    overflow: hidden;
}
