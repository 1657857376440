.card-realisation {
    &:hover{
        .inner{
            .container-img {
                picture{
                    img{
                        height: 110%;
                        width: 110%;
                    }
                }
            }
        }
    }
    .inner{
        background-color: $secondary;
        border-radius: 2.5rem;
        overflow: hidden;

        .container-img {
            position: relative;
            height: 0;
            left: -1px;
            width: calc(100% + 2px);
            padding-bottom: 60%;
            border-radius: 2.5rem;
            overflow: hidden;

            picture{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: 100%;
                border-radius: 2.5rem;
                overflow: hidden;

                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: width 0.5s ease, height 0.5s ease;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        h2{
            width: 100%;
            margin: 0;
            line-height: 1.4;
            margin-top: -2rem;
            padding: 4rem 5rem 2.5rem;
            font-size: 2rem;
            text-transform: unset;
            border: 1px solid $white;
            border-bottom-left-radius: 2.5rem;
            border-bottom-right-radius: 2.5rem;
        }
    }
}
