/* main: ../style.scss*/
//@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.3;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 12rem;
    line-height: 1.2;

    @include media-breakpoint-down(sm){
        font-size:8rem;
    }
}

h2{
    font-size: 3.5rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    line-height: 1.2;

    @include media-breakpoint-down(sm){
        font-size: 2.5rem;
    }
}

h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 8rem;
    font-weight: 500;
    line-height: 1.2;

    @include media-breakpoint-down(sm){
        font-size: 5rem;
    }

    @include media-breakpoint-down(xs){
        font-size: 4rem;
    }

    span{
        font-family: 'Abril Fatface', serif;
        font-weight: normal;
    }
}

h4, h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1.2;
}

h1, h2, h3, h4, h5{
    @include media-breakpoint-down(md){
        br {
            content: "";
        }

        br:after {
            content: " ";
        }
    }
}

p{
    margin-bottom: 2rem;
}

.smaller-font{
    font-size: 0.8em;
}

ul{
    padding-left: 1em;

    li{
        margin-bottom: 10px;
    }
}

.light-font{
    font-weight: 300;
}

.text-color{
    &.white{
        color: $white;
    }

    &.red{
        color: #EA584A;
    }

    &.blue{
        color: $primary;
    }

    &.green{
        color: #04A06F;
    }

    &.pink{
        color: #F7AEA4;
    }

    &.yellow{
        color: #F59C32;
    }
}
