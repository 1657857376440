.info-img{
    @include media-breakpoint-down(xs){
        padding-top: 0;

        .col.border-radius{
            border-radius: 0 !important;
            margin-bottom: 4rem;
        }
    }

    h2{
        font-family: 'Montserrat', sans-serif;
        font-size: 8rem;
        font-weight: 800;
        text-transform: initial;
        line-height: 1;

        span{
            font-family: 'Abril Fatface', sans-serif;
            font-size: 9rem;
        }
    }

    h3{
        font-family: 'Montserrat', sans-serif;
        font-size: 3rem;
        font-weight: 800;
        color: $primary;
    }

    .btn{
        margin-top: 3rem;
        --mainColor: #{#D96252};
        --secondaryColor: #{$white};
    }

    &.secondary-background{
        color: $white;
    }

    .col.content{
        @include media-breakpoint-down(xs){
            order: 2;
        }
    }

    .col.border-radius{
        overflow: hidden;
        min-height: 52rem;

        @include media-breakpoint-down(sm){
            min-height: 30rem;
        }

        @include media-breakpoint-down(xs){
            order: 1;
        }

        &:not(.contain){
            img{
                object-fit: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                width:100%;
                height: 100%;
                transform: translate(-50%, -50%);
            }
        }

        .thumbnails{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            cursor: pointer;

            &:hover{
                img.icon{
                    width: 7rem;
                    height: 7rem;
                }
            }

            img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                &.icon{
                    z-index: 10;
                    width: 5rem;
                    height: 5rem;
                    transition: width 0.5s ease, height 0.5s ease;
                }

                &.thumb{
                    width: 101%;
                    height: 101%;
                }
            }
        }
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        &.left{
            border-bottom-left-radius: 5rem;
            border-top-left-radius: 5rem;
        }

        &.right{
            border-bottom-right-radius: 5rem;
            border-top-right-radius: 5rem;
        }
    }
}
