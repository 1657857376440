.row{
    &.desktop{
        @include media-breakpoint-down(md){
            display: none;
        }
    }
    &.mobile{
        display: none;

        .outils{
            .inner {
                height: auto;
                padding-bottom: 0;

                .center {
                    position: relative;
                    padding-bottom: 6rem;

                    label{
                        padding-bottom: 3rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(md){
            display: block;
        }
    }
}
.button-cta{
    .btn{
        width: 100%;
        --padding: 2.2rem 2rem;
    }
}
.outils{
    font-size: 0;
    padding: 4rem 0;
    overflow: hidden;

    ul{
        padding: 4rem 6rem;
        display: inline-block;
        font-size: 2rem;
        width: 100%;
        vertical-align: top;

        li{
            margin-left: 2rem;
        }
    }
    .block-outil{
        position: relative;
        display: inline-block;
        padding: 4rem;
        width: 100%;
        vertical-align: top;
        font-size: 1.8rem;

        &.background-green{
             .over, .inner .center{
                 background: $green;
                 color: $white;
             }
         }

        &.background-pink{
            .over, .inner .center{
                background: $pink;
                color: $white;
            }
        }

        &.background-blue{
            .over, .inner .center{
                background: $primary;
                color: $white;
            }
        }

        &.background-yellow{
            .over, .inner .center{
                background: $yellow;
                color: $white;
            }
        }

        &:hover{
            .center{
                &:after{
                    animation: bounceRight 2s ease infinite;
                }
            }
            .over{
               opacity: 0 !important;
            }
        }

        .inner{
            position: relative;
            border-radius: 5rem;
            overflow: hidden;
            height: 0;
            padding-bottom: 60%;

            .center{
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &:after{
                    content: '';
                    position: absolute;
                    right: 3rem;
                    bottom: 3rem;
                    width: 6rem;
                    background: url('../images/icons/arrow-big.svg') no-repeat center center / 60%;
                    height: 6rem;
                    filter: invert(100%) sepia(92%) saturate(5%) hue-rotate(255deg) brightness(108%) contrast(100%);
                    border-radius: 100%;
                    border: 2px solid $black;

                    @keyframes bounceRight {
                        0%,
                        20%,
                        50%,
                        80%,
                        100% {
                            -ms-transform: translateX(0);
                            transform: translateX(0);
                        }
                        40% {
                            -ms-transform: translateX(-30px);
                            transform: translateX(-30px);
                        }
                        60% {
                            -ms-transform: translateX(-15px);
                            transform: translateX(-15px);
                        }
                    }
                }

                .content{
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    position: relative;
                    padding: 4rem 4rem;
                    flex: 1;
                    overflow: hidden;

                    label{
                        font-size: 4.2rem;
                        font-weight: 800;
                        text-transform: uppercase;
                        line-height: 1;

                        span{
                            font-size: 3rem;
                            display: block;
                            font-weight: 100;
                        }
                    }

                    .over{
                        position: absolute;
                        padding: 4rem 4rem;
                        top: 0;
                        left: 0;
                        opacity: 1;
                        width: 100%;
                        height: 100%;
                        transition: opacity 0.5s ease;
                        border-radius: 5rem;
                    }

                    img{
                        width: 9rem;
                        margin-bottom: 3rem;
                    }

                    h4{
                        font-weight: bold;
                        font-size: 3rem;
                        margin-bottom: 3rem;
                    }

                    p{
                        margin: 0;
                    }
                }
            }
        }
    }
}
