section.banner {
    position: relative;
    padding: 0;
    background: $secondary;
    transition: background-color 1s ease;
    height: 100vh;


    .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($primary, 0);
        z-index: 0;
        transition: background 0.5s ease;
    }

    &.box-shadow{
        &.bottom-color{
            &:after{
                bottom: -4rem;
            }
        }
    }

    &.bottom-color{
        &:after{
            content: '';
            position: absolute;
            background: $secondary;
            height: 50vh;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: 1;
        }
        &.white{
            &:after{
                background: $white;
            }
        }
        &.grey{
            &:after{
                background: #F3F4F4;
            }
        }
        &.secondary{
            &:after{
                background: $secondary;
            }
        }
        &.pink{
            &:after{
                background: $pink;
            }
        }
        &.green{
            &:after{
                background: $green;
            }
        }
        &.blue{
            &:after{
                background: $blue;
            }
        }
        &.light-blue{
            &:after{
                background: #F2FAF8;
            }
        }
        &.yellow{
            &:after{
                background: $yellow;
            }
        }
        &.white{
            &:after{
                background: $white;
            }
        }
    }

    &.home{
        & > .inner{
            .slide{
                position: relative;

                .nextSlide{
                    cursor: pointer;
                }

                .arrowScroll{
                    position: absolute;
                    top: 100%;
                }

                #arrowScroll-1{
                    width: 250px;
                    left: 0;
                    transform: rotate(-90deg);

                    @include media-breakpoint-down(sm){
                        width: 100px;
                        left: 5%;
                    }
                }
                #arrowScroll-2{
                    height: 250px;
                    left:41%;

                    @include media-breakpoint-down(sm){
                        height: 100px;
                        left: 36%;
                    }
                }
            }
        }
    }

    &.single {
        padding-top: 80vh;
        height: auto;
        transition: background-color 0s ease;

        & > .inner {
            .breadcrumbs{
                position: absolute;
                display: flex;
                align-items: center;
                top: -6rem;
                left:6rem;
                font-weight: 700;
                color: #6F787D;
                transition: left 0.5s ease, opacity 0.5s ease;

                a{
                    color: #6F787D;

                    &:after{
                        content: "|";
                        font-weight: 400;
                        padding: 0 1rem;
                        color: #6f787d;
                    }

                    &:last-child{
                        &:after{
                            content: none;
                        }
                    }

                    &:hover{
                        color: $green;

                        &:after{
                            color: #6f787d;
                        }
                    }
                }

                p{
                    margin:0;
                }

                img{
                    margin-right: 1rem;
                    width:2.5rem;
                }

                .back{
                    //position: absolute;
                    //left: 0rem;
                    //opacity: 0.6;

                    &:hover{
                        //left: 2.5rem;
                        //opacity: 1;
                    }
                }
            }

            &:first-child {
                position: fixed;
                white-space: normal;

                &.politiques-bg{
                    background: url('../images/Politiques.png') no-repeat 120% bottom / 48%;

                    @include media-breakpoint-down(sm){
                        background-position: 50% 115%;
                        background-size: 90%;
                    }
                }

                &.team-bg{
                    background: url('../images/equipe-header.png') no-repeat 90% bottom / 44%;

                    @include media-breakpoint-down(sm){
                        background-position: 60% bottom;
                        background-size: 58%;
                    }
                }

                .row{
                    margin: -10rem 0 0;

                    @include media-breakpoint-down(sm){
                        margin: 0 -6rem;
                    }
                }

                h2{
                    color: $secondary;
                    font-family: 'Abril Fatface', serif;
                    font-size: 7rem;
                    font-weight: 500;

                    @include media-breakpoint-down(sm){
                        font-size: 6rem;
                    }
                }
            }

            &:nth-child(2) {
                position: relative;
                z-index: 2;
                background: $white;
                border-radius: 5rem;
                padding-top: 14rem;
                padding-bottom: 10rem;

                &.br-bottom-none{
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .container{
                    position: relative;

                    //a.back{
                    //    position: absolute;
                    //    display: flex;
                    //    align-items: center;
                    //    top: -6rem;
                    //    left: 6rem;
                    //    font-weight: 800;
                    //    color: $secondary;
                    //    opacity: 0.6;
                    //    transition: left 0.5s ease, opacity 0.5s ease;
                    //
                    //    img{
                    //        margin-right: 1rem;
                    //        width:2.5rem;
                    //    }
                    //
                    //    &:hover{
                    //        left: 2.5rem;
                    //        opacity: 1;
                    //    }
                    //}
                }

                &.boxshadow{
                    box-shadow: 0 3px 3.6rem rgba(#000000, 0.16);
                    margin-bottom: 4rem;
                }


                &.radius-bottom-none{
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                h3{
                    font-size: 7rem;
                    color: $secondary;
                    line-height: 1;
                    margin-top: 0;
                    margin-bottom: 5rem;
                    font-weight: 700;
                    font-family: 'Montserrat', sans-serif;

                    @include media-breakpoint-down(sm){
                        font-size:5rem;
                    }

                    span{
                        font-family: 'Abril Fatface', serif;
                    }

                    &.smaller{
                        font-size: 3.5rem;

                        @include media-breakpoint-down(sm){
                            font-size:2.5rem;
                        }
                    }
                }


                .btn{
                    font-family: 'Montserrat', sans-serif;
                    --font-size: 2.4rem;
                    font-weight: 500;
                    width: 100%;
                    margin-top: 2rem;
                    padding: 2rem 3rem;
                    min-height: 9rem;
                    line-height: 1.2;

                    &:first-child{
                        margin: 0;
                    }
                }
            }
        }
    }

    &.page-projet{
        padding-top: 100vh;

        > .inner{
            &:first-child{
                .row{
                    margin: 0 -3rem;
                }

                h2{
                    margin: 2rem 0 0;
                    font-size: 9rem;

                    @media screen and (max-width: 1024px) and (max-height: 768px) {
                        font-size: 7rem;
                    }
                }
            }
        }
    }

    &.page-projet-small{
        //padding-top: 85vh;

        & > .inner:first-child {
            min-height: 85vh;
            padding-top: 10rem;

            .row{
                margin: 0 -3rem;
            }

            h2{
                font-size: 6rem;
                color: $primary;
                margin: 0 0 1rem;
                padding-bottom: 0;

                @include media-breakpoint-down(sm){
                    font-size:4.5rem;
                }
            }

            h1{
                font-size: 8rem;
                font-family: Montserrat,sans-serif;

                @include media-breakpoint-down(sm){
                    font-size:6rem;
                }
            }
        }

        & > .inner:nth-child(2) {
            background: none;
            border-radius: 0;
            padding-top: 0;
            padding-bottom: 0;

            .container{
                .row{
                    &:first-child{
                        position: relative;
                        background: #fff;
                        border-radius: 5rem;
                        height: 70vh;
                        overflow: hidden;

                        img{
                            object-fit: cover;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 101%;
                            height: 101%;
                        }
                    }
                    &:nth-child(2){
                        margin-top: -30vh;
                        padding-bottom: 8rem;

                        &.single-col{
                            margin-top: 0;
                            padding: 8rem 20rem;

                            @include media-breakpoint-down(sm){
                                padding: 8rem 0;
                            }
                        }

                        .col{
                            padding-top: 40vh;
                            color: $white;

                            @include media-breakpoint-down(sm){
                                padding-top: 10rem;
                            }

                            h2{
                                padding: 10rem 0 2rem;
                                font-family: Montserrat,sans-serif;
                                font-weight: 700;
                                font-size: 3.5rem;
                                line-height: 1.2;

                                @include media-breakpoint-down(sm){
                                    font-size:3rem;
                                }

                                &:first-child{
                                    padding-top: 0;
                                }
                            }

                            &:first-child{
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn {
        --padding: 1rem 3rem;
        --font-size: 1em;
        font-family: 'Abril Fatface', serif;
    }

    & > .inner:first-child {
        position: sticky;
        z-index: 1;
        top: 0;
        min-height: 100vh;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;

        .slide {
            display: inline-flex;
            align-items: center;
            height: 100vh;
            width: 100%;
            white-space: normal;

            img {
                position: absolute;

                &.green-stamp {
                    top: 13%;
                    right: 10%;

                    @include media-breakpoint-down(md){
                        display: none;
                    }
                }

                &.heart-stamp {
                    top: 33%;
                    right: 4%;
                    max-width: 35rem;

                    @include media-breakpoint-down(md){
                        top: 13%;
                        right: 6%;
                        width: 40vw;
                    }

                    @include media-breakpoint-down(sm){
                        top: 11%;
                        right: 4%;
                    }
                }

                &.cool-stamp {
                    top: 64%;
                    right: 20%;
                    max-width: 50rem;

                    @include media-breakpoint-down(md){
                        top: 68%;
                        right: 6%;
                        width: 53vw;
                    }
                }
            }
        }
    }

    img.paralax{
        position: absolute;
        left: 50%;
        top: 0;
        object-fit:cover;
        max-width: 100%;
        z-index: 0;
        overflow:visible;
    }

    .container{
        position: relative;
        z-index: 10;
    }

    h1, h2 {
        position: relative;
        z-index: 10;
        text-align: left;
        font-size: 9rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: $white;
        line-height: 1.2;
        margin: 0;
        text-transform: inherit;

        @include media-breakpoint-down(sm){
            font-size: 4.5rem;
        }

        a {
            font-size: 12rem;
            font-family: 'Abril Fatface', serif;
            padding: 0 3rem 1rem;
            margin-top: -3rem;

            @include media-breakpoint-down(sm){
                font-size: 4.5rem;
                margin-top: 1rem;
            }

            &.white.inverted:hover {
                color: $primary;
            }
        }
    }

    h3 {
        margin: 2rem 0 0;
        color: $secondary;
        font-family: 'Abril Fatface', serif;
        font-size: 9rem;
        font-weight: 500;

        @include media-breakpoint-down(sm){
            font-size: 7rem;
        }
    }

    h2, h4 {
        span{
            font-family: 'Abril Fatface', serif;
        }
    }
}
