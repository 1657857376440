.slider{
    position: relative;
    max-width: 96rem;
    margin: 3rem auto;

    .slick-arrow{
        z-index: 10;
        transition: opacity 0.5s ease;

        &.slick-disabled{
            opacity: 0;
        }

        &.slick-prev{
            left: 16px;
            transform: translateY(0) rotate(180deg);
        }

        &.slick-next, &.slick-prev{
            height: 50px;
            width: 50px;
            padding: 10px;
            top: 25rem;
            background: #ffffff;
            border-radius: 100%;

            &:before {
                content: '';
                background: url('../images/icons/arrow-right.svg') no-repeat center center / contain;
                width: 100%;
                height:100%;
                display: block;
                opacity: 1;
            }
        }

        &.slick-next{
            right: 16px;
            transform: translateY(0);
        }
    }

    &.equipe{
        max-width: 120rem;

        .slick-slide{
            &>div{
                .item{
                    width: 25rem;
                    height: auto;
                    color: $white;
                    border-radius: 0;

                    label{
                        font-weight: bold;
                        font-size: 3rem;
                        margin: 2rem 0 1rem;
                        text-transform: uppercase;
                    }

                    &:after{
                       content: none;
                    }
                }
            }
        }
    }

    &.popdepot{
        margin: 0 -2rem;
        width: 55rem;
        max-width: 100%;
    }

    .slick-list{
        overflow: visible !important;
    }
    .slick-slide{
        &>div{
            margin: 2rem;

            .item{
                position: relative;
                height: 45rem;
                border-radius: 5rem;
                overflow: hidden;

                .btn{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 90%;
                    padding: 2rem;
                    --font-size: 2.5rem;
                    opacity: 0;
                    transition: opacity 0.5s ease, color 0.5s ease, background-color 0.5s ease;
                    z-index: 10;
                }

                &:after{
                    content: '';
                    background: $primary;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity 0.5s ease;
                    z-index: 0;
                }

                &:hover{
                    .btn{
                        opacity: 1;
                    }
                    &:after{
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}
.paginator-center{
    display: flex;
    align-items: center;
    justify-content: center;

    &.equipe{
        position: absolute;
        right: 0;
        bottom: 1rem;
        @include media-breakpoint-down(sm){
            position: static;
        }
    }
    a, button{
        margin: 0 1rem;
        --padding: 2rem;
        --font-size: 2.5rem;

        &.prev{
            transform: rotate(-180deg);

        }
        &.prev, &.next{
            position: relative;
            height: 7rem;
            width: 7rem;

            svg{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 3.6rem;
            }
        }

    }
}
