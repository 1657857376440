section.holiday {
    background: url('../images/holiday/Background.jpg') no-repeat center center / cover;
    padding: 0;

    .inner{
        position: relative;
        min-height: 100vh;
        padding: 10rem 0;

        picture{
            width: 36rem;
            max-width: 90%;


            &.text{
                width: 50rem;
            }
        }

        iframe{
            position: relative;
            aspect-ratio: 9 / 16;
            width: 100%;
        }

        hr{
            border-color: #333;
            margin: 3rem 0;
        }

        .arrow{
            position: absolute;
            width: auto;
            left: 50%;
            bottom: 3rem;
            transform: translateX(-50%);
            animation: bounceIn 2s infinite;
        }
    }
}

@keyframes bounceIn {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    40% {
        transform: translateX(-50%) translateY(-30px);
    }
    60% {
        transform: translateX(-50%) translateY(-15px);
    }
}
