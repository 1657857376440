section.single-post {
    padding-top: 0;

    &.job{
        &.banner{
            height: auto;
            padding-top: 15rem;

            h2 {
                font-family: 'Montserrat', sans-serif;
                font-weight: 800;
                color: $black;
                font-size: 10rem;

                @include media-breakpoint-down(sm) {
                    font-size: 6.5rem;
                }
            }

            h1 {
                font-family: 'Abril Fatface', serif;
                font-size: 8rem;
                font-weight: normal;
                color: $green;
                @include media-breakpoint-down(sm) {
                    font-size: 5.5rem;
                }
            }
        }

        h3 {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        .row{
            margin-top: 6rem;
        }
    }



    &.banner{
        padding-top: 75vh;
        background: none;

        .inner.fixed{
            min-height: 75vh;

            *:first-child{
                padding-top: 2rem;
            }

            h1{
                font-family: 'Montserrat', sans-serif;
                font-weight: 800;
                color: $white;
                font-size: 10rem;

                @include media-breakpoint-down(sm) {
                    font-size: 6.5rem;
                }
            }
        }
        &>.inner:nth-child(2) {
            h3 {
                font-size: 3rem;
                margin-bottom: 2rem;
            }

            .row{
                margin-top: 6rem;
            }
        }
    }



    .emplois {
        margin-top: 8rem;

        .info-emplois {
            border: 1px solid #283841;
            margin-right: 3rem;
            border-radius: 8rem;
            padding: 2rem 5rem 2rem 5rem;

            @include media-breakpoint-down(sm) {
                margin: 0 0 2rem 0;
            }

            span {
                font-size: 2.8rem;
                margin: 0;
            }
        }
    }

}

.job-contact{
    font-size: 3.6rem;
}
