.fiche-post{
    overflow: hidden;
    border-radius: 45px;
    background: #ffffff;
    box-shadow: 0 3px 2px rgba(#000000, 0.11);

    .img-post{
        position: relative;
        height: 20rem;

        img{
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }

    .content{
        padding: 2rem;

        span{
            display: block;
            color: $primary;
            font-size: 1.6rem;
            margin-bottom: 2rem;
        }

        h3{
            display: block;
            font-family: 'Montserrat', sans-serif;
            font-size: 2.2rem;
            font-weight: 800;
            margin-bottom: 2rem;
        }

        p{
            font-size: 1.5rem;
        }

        .actions{
            margin-top: 3rem;

            .shared{
                color: $secondary;
                margin-right: 1rem;
            }
        }
    }
}
