section{
    &.banner.single{
        .max{
            max-width: 70rem;
            width: 100%;
        }
    }

    &.histoire{
        .container, .container-fluid{
            .row{
                margin-top: 10rem;

                h4{
                    font-size: 3.5rem;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 4rem;
                    font-family: 'Montserrat', sans-serif;
                    text-transform: uppercase;
                }
            }
            &.devise{
                .col {
                    overflow: hidden;

                    @include media-breakpoint-down(md) {
                        padding: 0 0 0 0;
                    }

                    h4 {
                        margin-bottom: -8rem;
                        @include media-breakpoint-down(md) {
                            margin-bottom: 3rem;
                        }
                    }

                    img {
                        position: relative;
                        z-index: -1;
                        width: calc(100% + 12rem);
                        max-width: none;
                        margin: 0 -6rem;
                    }
                }
            }
            &.valeurs{
                text-align: center;

                .values-intro{
                    font-weight: 500;
                    font-size: 4rem;

                    b{
                        font-weight: 800;
                    }
                }

                .value{
                    max-width: 45rem;
                    margin: 0 auto 8rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img{
                        height: 14rem;
                    }

                    h5{
                        font-family: 'Abril Fatface', serif;
                        font-size: 5rem;
                        margin-top: 1.6rem;
                        font-weight: 500;
                        margin-bottom: 0;

                        @include media-breakpoint-down(sm){
                            font-size:3.5rem;
                        }
                    }

                    p{
                        margin-top: 3rem;
                        font-size: 2.4rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
