section.portfolio.banner{
    padding-top: 35vh;

    .img-banner{
        position: absolute;
        transform-origin: 50% 50%;

        &.tactique{
            top: -16vh;
            right: 68rem;
            transform: translateY(0) rotate(-38deg);
        }

        &.video{
            right: -10rem;
            top: 10vh;
            width: 59rem;
            transform: translateY(0) rotate(-16deg);
        }

        &.design{
            right: 52rem;
            top: 43vh;
            transform: translateY(0) rotate(-21deg);
        }

        &.web{
            right: 21rem;
            top: 66vh;
            width: 56rem;
            transform: translateY(0) rotate(-43deg);
        }
    }
}
section.card-section {
    padding-top: 8rem;

    .sticky-bg{
        position: sticky;
        top:0;
        left: 0;
        background: $secondary;
        width: 100%;
        z-index: 4;
        height: 7rem;
    }

    .filters {
        position: sticky;
        top: 3rem;
        z-index: 4;
        margin-top: -11rem;

        .col{
            display: flex;
            overflow: auto;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include media-breakpoint-down(md){
                justify-content: start;
            }
        }
    }
}
