section{
    position: relative;
    padding: 10rem 0;
    z-index: 2;
    background: var(--white);

    @include media-breakpoint-down(md){
        min-height: 0;
    }

    &.smaller-padding{
        padding: 5rem 0;
    }

    &.no-min{
        min-height: 0;
        overflow: hidden;
    }

    &.overflow-none{
        overflow: hidden;
    }

    &.center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .header{
        position: relative;
        max-width: 105rem;
        margin: 0 auto 5rem;
        text-align: center;
    }
}
