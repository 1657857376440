section.banner {
    &.single-projet{
        padding: 24rem 0 5rem;
        height: auto;

        .btn{
            --font-size: 0.9em;
            margin-bottom: 3rem;
        }
        h1{
            font-size: 7rem;
            text-transform: uppercase;
        }
        h2{
            font-family: Abril Fatface, serif;
            font-size: 5.5rem;
            margin-top: 4rem;
            font-weight: 500;
        }
    }
    &.page-projet {
        .image-projet {
            height: 100%;
            max-height: 74vh;
            width: auto;
            max-width: 64rem;
            border-radius: 5rem;

            //@media screen and (max-width: 1024px) and (max-height: 768px) {
            //    max-height: 50vh;
            //}

            @include media-breakpoint-down(md) {
                max-height: 35vh;
            }
        }

        h2 {
            @include media-breakpoint-down(sm) {
                font-size: 4.5rem !important;
            }
        }
    }

    &.page-projet, &.page-projet-small {

        .inner:first-child {
            padding-top: 10rem;
        }

        h3 {
            font-family: Abril Fatface, serif;
            margin-bottom: 1rem;
            font-size: 3.5rem;
            font-weight: 500;
        }

        h2 {
            font-family: Montserrat, sans-serif;
            margin: 0;
            padding-bottom: 5rem;
            font-size: 9rem;
            font-weight: 800;
            color: var(--secondaryColor);

        }

        .more-col {
            @include media-breakpoint-down(md) {
                padding-top: 10rem;
            }
        }

        .more {
            position: absolute;
            top: 5rem;
            right: 3rem;
            max-width: calc(100% - 12rem);
            transition: right 0.5s ease, background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;

            @include media-breakpoint-down(md) {
                top: 2.5rem;
            }

            &:hover {
                right: -1rem;
            }

            svg {
                width: 26px;
            }
        }
    }

    &.page-projet-small {
        .inner{
            &:first-child h1 {
                font-size: 4.5rem !important;
            }
        }

        h2 {
            @include media-breakpoint-down(sm) {
                font-size: 3rem !important;
            }
        }

        .more {
            top: -8rem;
            right: 6rem;

            @include media-breakpoint-down(md) {
                top: -10rem;
            }

            &:hover {
                right: 2rem;
            }
        }
    }
}

section.description {
    padding-top: 0;
    padding-bottom: 4rem;

    .large {
        max-width: 174rem;

        .secondary-back-background {
            &:before {
                content: '';
                border-radius: 5rem;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 25vw);
                background: $secondary;

                @include media-breakpoint-up(xxl) {
                    height: calc(100% - 12vw);
                }
            }
        }

        h1 {
            font-family: Abril Fatface, serif;
            font-weight: normal;
            font-size: 5rem;
            margin: 0;
        }

        .container {
            &.margin-negative {
                padding-top: 4rem;

                .green-back {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5rem;
                    overflow: hidden;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }

                    img {
                        position: relative;
                        z-index: 1;

                        &.left {
                            padding-bottom: 10%;
                        }

                        &.right {
                            padding-top: 10%;
                        }
                    }

                    &:before {
                        content: '';
                        border-radius: 5rem;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 80%;
                        transform: translateY(-50%);
                        background: $green;
                    }
                }
            }
        }
    }
}

section.solutions-project {

    h2 {
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        font-size: 4rem;
        text-transform: uppercase;
    }

    h3 {
        font-weight: 800;
        font-size: 5.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 3.5rem;
        }
    }

    p {
        margin-bottom: 2rem;
    }
}

section.right-background {
    &.light-blue-background {
        background: #ffffff !important;

        &.smaller-padding {
            .container {
                &:before {
                    top: -5rem;
                    height: calc(100% + 10rem);
                }
            }
        }

        .container {
            position: relative;

            &:before {
                content: '';
                background: #f2faf8;
                position: absolute;
                top: -10rem;
                left: 0;
                width: 100vw;
                height: calc(100% + 20rem);
                border-top-left-radius: 5rem;
                border-bottom-left-radius: 5rem;
            }
        }
    }
}

img.side {
    position: absolute;
    max-width: none;
    top: 0;

    @include media-breakpoint-down(sm) {
        position: relative;
        max-width: 100%;
    }

    &--align-left {
        left: 0;
    }

    &--align-right {
        right: 0;
    }
}

.min-height {
    min-height: 80vh;
    padding: 0;

    @include media-breakpoint-down(sm) {
        min-height: 0;
    }
}

section.results {
    background: #F2FAF8;
    z-index: 10;

    &:not(.with-padding) {
        padding-bottom: 0;
    }

    h2 {
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        font-size: 4rem;
        margin-bottom: 4rem;
        text-transform: uppercase;
    }

    h3 {
        font-weight: 800;
        font-size: 5.5rem;
    }

    .bubbles {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .bubble-resultat {
            border-radius: 5rem;
            display: flex;
            margin-bottom: 2rem;
            justify-content: center;
            align-items: center;
            padding: 2rem 5rem 2rem 5rem;

            &:nth-child(odd) {
                align-self: flex-start;
            }

            &:last-child {
                margin-bottom: -4rem;
            }

            strong {
                font-size: 4rem;
                margin-bottom: 0;
                color: white;
                padding: 0 1rem;
            }

            p {
                font-size: 1.8rem;
                font-weight: bold;
                color: white;
                margin: 0;
                padding: 0 1rem;
            }
        }
    }
}
