/* main: ../style.scss*/
footer {
	position: relative;
	padding: 5rem 0 0;
	background: $secondary;
	color: #ffffff;
	font-size: 2rem;
	z-index: 10;

	.top{
		padding-bottom: 2rem;

		.address{
			line-height: 1.5;

			.logo-link{
				max-width: 13rem;
				width: 100%;
				display: block;
			}

			a:not(.logo-link){
				display: inline-block;
				width: 100%;
				transition: transform 0.5s ease;

				&:hover{
					transform: translateX(1.5rem);
				}
			}
		}
	}

	.bottom{
		background: #1F272C;
		padding: 2rem 0;
	}

	.menu{
		a{
			transition: transform 0.5s ease;
			margin-bottom: 1rem;

			&:hover{
				transform: translateX(1.5rem);
			}

			&:last-child{
				margin: 0;
			}
		}
	}

	a{
		display: block;
		color: $white;
		line-height: 1.2;

		&:hover{
			color: $primary;
		}
	}

	.lang{
		height: 2.2rem;

		a{
			display: inline-block;
		}
	}
}
