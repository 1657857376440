.see-achievements {
    overflow: hidden;
    background-color: #F7AEA4;

    //&:before {
    //    content: '';
    //    background: #000000;
    //    opacity: 0.5;
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100%;
    //    z-index: 2;
    //}

    a {
        position: relative;
        z-index: 10;

        svg {
            margin-left: 2rem;
            width: 26px;
        }
    }

    //img{
    //    position: absolute;
    //    z-index: 1;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100vh;
    //    object-fit: cover;
    //}
}
