.example-cellphone{
    position: relative;

    .img-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        .img{
            padding: 0 2rem;

            &.left{
                padding-bottom: 10%;
            }

            &.right{
                padding-top: 10%;
            }
        }
    }
}
