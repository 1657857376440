/* main: ../style.scss*/
header {
    position: fixed;
    top: -18rem;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 5rem 0 3rem;
    pointer-events: none;
    background: rgba($secondary, 0);
    transition: background 0.5s ease, top 0.5s ease;

    &.sticky, &.toTop{
        top: 0;
    }

    .lang{
        text-transform: uppercase;
    }

    &.toTop{
        background: rgba($secondary, 0);

        .main{
            .secondary{
                svg{
                    path{
                        fill: $secondary;
                    }
                }
                label{
                    color: $secondary;
                }
            }
        }

        &:not(.active){
            &.secondary{
                .main{
                    color: $secondary;

                    svg{
                        path{
                            fill: $secondary;
                        }
                    }

                    .btn{
                        --mainColor: #{$secondary};

                        &:hover{
                            color: $white;

                            span{
                                color: $secondary;
                            }
                        }

                        span{
                            color: $white;
                        }
                    }

                    a{
                        color: $secondary;

                        &.menu-toggle{
                            @include media-breakpoint-down(sm){
                                background-color: transparent;
                                border: none;
                            }
                        }

                        &.btn{
                            --mainColor: #{$secondary};

                            &:hover{
                                color: $white;

                                span{
                                    color: $secondary;
                                }
                            }

                            span{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.sticky:not(.toTop){
        background: $secondary;
    }


    .main{
        position: relative;
        z-index: 100;
        color: $white;

        .logo-link{
            margin-left: -2rem;
        }

        svg{
            width: 100%;
            max-width: 19rem;
            min-width: 14rem;
            margin-right: 1.5rem;

            @include media-breakpoint-down(sm){
                margin-right: 0;
            }

            path{
                transition: fill 0.5s ease;
            }
        }

        span.slogan{
            font-weight: 400;
            font-size: 1.8rem;
            margin: 0;
            transition: color 0.5s ease;


            @include media-breakpoint-down(sm){
                display: none;
            }
        }

        a, button{
            pointer-events: auto;
            margin-right: 2.5rem;
            padding: 1.2rem 2rem;
            font-size: 2rem;

            @include media-breakpoint-down(sm){
                display: none;
                margin-right: 0;
            }

            &.logo-link, &.lang{
                @include media-breakpoint-down(sm){
                    display: block;
                }
            }

            &:last-child{
                margin: 0;
            }

            &.menu-toggle{
                position: relative;

                @include media-breakpoint-down(sm){
                    display: block;
                    background: $primary;

                    &:before {
                        top: 31%;
                    }
                    &:after{
                        top: 69%;
                    }
                    .center-line{
                        display: block;
                        opacity: 1;
                        top: 50%;
                    }
                }

                &:before, &:after, .center-line{
                    content: '';
                    position: absolute;
                    width: 3.4rem;
                    height: 0.3rem;
                    border-radius: 2px;
                    background: $white;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%) rotate(0);
                    transition: transform 0.5s ease 0.2s, opacity 0.5s ease 0.2s;

                    @include media-breakpoint-down(sm){
                        opacity: 1;
                        width: 2.8rem;
                        height: 3px;
                        background: $secondary;
                    }
                }

                .center-line{
                    display: none;
                }

                span{
                    text-align: center;
                    opacity: 1;
                    width: 8.5rem;
                    transition: opacity 0.5s ease, width 0.5s ease 0.2s;

                    @include media-breakpoint-down(sm){
                        display: inline-block;
                        opacity: 0;
                        width: 1.5rem;
                    }
                }
            }
        }
    }

    &.active{
        .main  a.menu-toggle{
            @include media-breakpoint-down(sm) {
                background: $secondary;
            }
            .center-line{
                opacity: 0;
                background: rgba($primary, 0);
            }
            &:before, &:after{
                opacity: 1;

                @include media-breakpoint-down(sm){
                    background: $white;
                }
            }
            &:before{
                transform: translate(-50%, -50%) rotate(45deg);

                @include media-breakpoint-down(sm){
                    top: 50%;
                }
            }
            &:after{
                transform: translate(-50%, -50%) rotate(-45deg);

                @include media-breakpoint-down(sm){
                    top: 50%;
                }
            }
            span{
                opacity: 0;
                width: 1.5rem;
            }
        }

        .over-menu{
            &.active{
                height: 100%;

                .inner{
                    .menu{
                        @for $i from 0 through 13 {
                            .item:nth-child(#{$i}) {
                                transition: transform 0.5s ease #{($i * 0.1) + 's'}, opacity 0.5s ease #{($i * 0.1) + 's'};
                            }
                        }

                        .item{
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }

    &.closing{
        .main  a.menu-toggle{
            &:before, &:after{
                transition: transform 0.5s ease, opacity 0.5s ease;
            }
            span{
                transition: opacity 0.5s ease 0.2s, width 0.5s ease;
            }
        }
    }

    .over-menu{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
        background: #283841;
        z-index: 90;
        pointer-events: auto;
        transition: height 0.5s ease;
        overflow: hidden;

        .inner{
            padding-top: 20rem;
            padding-bottom: 12rem;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            min-height: 100vh;

            &:after{
                content: url('../images/menu-signature.svg');
                position: absolute;
                bottom: 0;
                right: 0;
                width: 39vw;
                transform: translate(16%,17%) rotate(-27deg);

                @include media-breakpoint-down(sm){
                    width: 68vw;
                }
            }

            .menu{
                .item{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 3rem;
                    opacity: 0;
                    transform: translateX(-50%);
                    transition: transform 0.5s ease, opacity 0.5s ease;

                    @include media-breakpoint-down(sm){
                        flex-direction: column;
                    }

                    a{
                        font-size: 5rem;
                        font-weight: 800;
                        color: $white;
                        padding-left: 0;
                        transition: padding-left 0.25s ease, background-color 0.5s ease, color 0.5s ease;

                        @include media-breakpoint-down(sm){
                            font-size: 4.5rem;
                        }

                        &:hover, &:active{
                            color: $primary;
                            padding-left: 10px;
                        }
                    }

                    .sub-menu{
                        display: inline-block;
                        margin: -1rem 0 -1rem 1rem;
                        @include media-breakpoint-down(sm){
                            margin: 0.25rem -1rem 0;
                        }
                        a{
                            margin: 1rem 1rem 1rem 2rem;
                            font-weight: 500;
                            font-size: 3rem;
                            padding: 1rem 2rem;

                            @include media-breakpoint-down(sm){
                                margin: 1rem;
                            }

                            &:hover, &:active{
                                color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}
