/* main: ../style.scss*/
.reseaux{
	padding: 0;
	min-height: 0;

	.header{
		margin-bottom: 5rem;

		h3{
			text-align: left;
		}
	}

	.col{
		position: relative;
		padding-top: 12rem;
		padding-bottom: 20rem;
		overflow: hidden;

		@include media-breakpoint-down(md){
			padding-top: 6rem;
			padding-bottom: 6rem;

			&:last-child{
				height: 75vw;
			}
		}

		.box{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&.inview{
				.gravbox{
					opacity: 1;
				}
			}
			.gravbox{
				position: absolute;
				opacity: 0;
				transition: opacity 0.5s ease;
				top: 0;
				left: 0;
				pointer-events: none;

				&.heart{
					display: flex;
					justify-content: center;
					align-items: center;
					background: $red;
					padding: 2rem;
					padding-top: 3rem;
					width: 15rem;
					height: 15rem;
					border-radius: 100%;

					@include media-breakpoint-down(sm){
						width: 10rem;
						height: 10rem;
					}
				}

				.btn{
					font-size: 6rem;
					border-radius: 14rem;
					padding: 2.5rem 4rem;
					font-weight: 800;
					pointer-events: auto;


					@include media-breakpoint-down(sm){
						font-size: 4rem;
						padding: 1rem 2.5rem;
					}
				}
			}
		}
	}

	p{
		line-height: 1.4;
	}

	.btn{
		--font-size: 1em;
		--padding: 1rem 2rem;
	}
}
