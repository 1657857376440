.banner.home{
    position: relative;
    cursor: url('../images/icons/play-white-small.png') 16 16, pointer;

    &.active{
        cursor: url('../images/icons/pause-white-small.png') 16 16, pointer;

        .bg-video {
            opacity: 1;
        }
    }

    .bg-video {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        background: no-repeat center;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        z-index: 10;
        width: 120%;
        height: 120%;
        transition: opacity 0.5s ease;
    }

    .container{
        height: 100vh;

        .row{
            height: 100%;

            .col{
                height: 100%;

                .stamp-container{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 10;

                    .stamp{
                        color: $white;
                        padding: 2rem;
                        width: 30rem;
                        height: 20rem;
                        border-radius: 2rem;
                        transition: transform 0.8s ease;
                    }

                    &.tactic{
                        transform: translate(-57rem,-17rem) rotate(-20deg);

                        @include media-breakpoint-down(sm){
                            transform: translate(-70%, -201%) rotate(-20deg) !important;
                        }

                        @include media-breakpoint-down(md){
                            transform: translate(-111%, -185%) rotate(-20deg);
                        }

                        .stamp{
                            background: $green;
                        }
                    }
                    &.design{
                        transform: translate(22rem,-18rem) rotate(19deg);

                        @include media-breakpoint-down(sm){
                            transform: translate(-11%, -172%) rotate(19deg) !important;
                        }

                        @include media-breakpoint-down(md){
                            transform: translate(23%, -141%) rotate(19deg);
                        }

                        .stamp{
                            background: $pink;
                        }
                    }
                    &.video{
                        transform: translate(-47rem,13rem) rotate(8deg);

                        @include media-breakpoint-down(sm){
                            transform: translate(-76%, 68%) rotate(8deg) !important;
                        }

                        @include media-breakpoint-down(md){
                            transform: translate(-115%, 68%) rotate(8deg);
                        }

                        .stamp{
                            background: $yellow;
                        }
                    }
                    &.web{
                        transform: translate(22rem,12rem) rotate(7deg);

                        @include media-breakpoint-down(sm){
                            transform: translate(-31%, 116%) rotate(-7deg) !important;
                        }

                        @include media-breakpoint-down(md){
                            transform: translate(-1%, 105%) rotate(-7deg);
                        }

                        .stamp{
                            background: $blue;
                        }
                    }

                    span{
                        display: block;
                        font-weight: 100;
                        font-size: 3rem;

                        &.alt{
                            position: absolute;
                            bottom: 2rem;
                            right: 2rem;
                        }
                    }

                    label{
                        font-size: 3.4rem;
                        font-weight: 800;
                        text-transform: uppercase;
                        line-height: 1;

                        span{
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
    .logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 48rem;
        width: 100%;
    }
}
